<template>
  <div :class="classBlock">
    <div :class="classBlock + '-background background'"></div>
    <v-main :class="[classBlock + '-content', 'page-content']">
      <v-row>
        <v-col>
          <header-cc logoColor="black"></header-cc>
          <h1>
            404
          </h1>
          <p>
            Ingrédients introuvables.
          </p>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import HeaderCc from '../components/HeaderCc.vue'

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'noPageFound',
    // components imported
    components: {HeaderCc},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // metas
    metaInfo: {
      title: 'Love is in the socks - NFT collectibles',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Love is in the socks - NFT collectible',
      }],
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'noPageFound'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'noPageFound';
    .noPageFound {
        // --------------------------------------------------------------------- CONFIG
        &-background{
          background-color:$home;
          width: 100vw;
          min-height: 100vh;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
        }
        // --------------------------------------------------------------------- BLOCK

        // --------------------------------------------------------------------- ELEMENTS
        p{
          margin:30px 0
        }
        &-content{
          text-align: center;
        }
        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

