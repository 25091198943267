// imports
import Vue from 'vue'
import {ERequestType, fetchGetHelper} from '../../tools/fetch/fetchHelper'

// ------------------------------------------- States
const state = {
  projects: [],
  projectIsLoaded: false
}

// ------------------------------------------- Getters
const getters = {
  getProjectsIsLoaded: function (state) {
    return state.projectIsLoaded
  },
  getAllProjects: function (state) {
    return state.projects
  },
  getProject: (state) => (url) => {
    return state.projects[url]
  }
}

// ------------------------------------------- Mutations
const mutations = {
  /**
   * set data to a specific id
   * @param {*} state 
   * @param {url: string, data: Object} dbData 
   */
  setProject (state, dbData) {
    Vue.set(state.projects, dbData.url.toString(), dbData.data)
  },
}

// ------------------------------------------- Actions
const actions = {
  /**
   * init store datas
   * @param {*} param0 
   */
  initStore: function ({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGetHelper(ERequestType.GET_PROJECTS)
      .then((datas) => {
        datas.entries.forEach(data => {
          commit('setProject', {
            url: data.url,
            data: data
          })
        });
        state.projectIsLoaded = true;
        resolve()
      })
      .catch((err) => {
        console.log(new Error('Project init store', err))
        reject(err)
      })
    })
  }
}

/*************************************
 Export module
 **/
export default {
  namespaced: true, state, getters, mutations, actions
}