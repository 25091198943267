import axios from 'axios'

export const ERequestType = {
  GET_PROJECTS : 'collections/get/projects',
}

/**
 * Axios post helper
 * @param {ERequestType} pRequest 
 * @param {Json Object} pJsonDatas 
 * @param {String} token 
 */
export const fetchPostHelper = (
  pRequest,
  pJsonDatas,
  token = undefined
) => {
  // Build request url
  let baseRequest = [
    // base url
    process.env.VUE_APP_BACK_URL,
    // base api
    'api',
    // string request after type, ex: get/establishment
    `/${pRequest}`
    // token
    // "?token=", token,
  ].join('')

  if (token) { baseRequest += '?token=' + token }

  // Return promise
  return new Promise((resolve, reject) => {
    // await fetch
    // const response = axios.post(baseRequest, pJsonDatas)
    axios
      .post(baseRequest, pJsonDatas)
      .then((res) => {
        console.log('POST request succed')
        resolve(res.data)
      })
      // catch error on request
      .catch((error) => {
        reject(error.response)
      })
  })
}

/**
 * Axios get Helper
 * @param {ERequestType} pRequest 
 * @param {String} token 
 */
export const fetchGetHelper = (
  pRequest,
  token = undefined
) => {
  // Build request url
  let baseRequest = [
    // base url
    process.env.VUE_APP_BACK_URL,
    // base api
    'api',
    // string request after type, ex: get/establishment
    `/${pRequest}`
    // token
    // "?token=", token,
  ].join('')

  if (token) { baseRequest += '?token=' + token }

  // Return promise
  return new Promise((resolve, reject) => {
    // await fetch
    // const response = axios.post(baseRequest, pJsonDatas)
    axios
      .get(baseRequest)
      .then((res) => {
        console.log('POST request succed')
        resolve(res.data)
      })
      // catch error on request
      .catch((error) => {
        reject(error.response)
      })
  })
}
