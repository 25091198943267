<template>
  <div :class="classBlock">
    <div :class="classBlock + '-background background'"></div>
    <header-cc logoColor="black"></header-cc>
    <v-main class="page-content">
      <v-row>
        <v-col>
          <div :class="classBlock+'-pict-mobile'"></div>
          <div :class="classBlock + '-text'">
            <h2>
              ... Comming really soon ...
            </h2>
            <p>
              an nft collection<br>
              of<br>
              Love, Socks and Rock'n roll
            </p>
            <!-- <h3>Blandine Perrin-Mermoz</h3>
            <p>
              Cantine mobile pour événements culturels et cinématographiques.
            </p> -->
            <!-- <router-link :to="'/contact'">
              <div class="button">
                Contact
              </div>
            </router-link> -->
            <!-- <ul>
              <li v-for="(project,i) in projects" :key="i">
                <router-link :to="'/projects/'+project[0]">{{project[1].name}}</router-link>
              </li>
            </ul>
            <router-link to="About">About</router-link> -->
          </div>
          <div :class="classBlock+'-pict'"></div>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import { mapGetters } from 'vuex'
  import HeaderCc from '../components/HeaderCc.vue'

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'Home',
    // components imported
    components: {HeaderCc},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // metas
    metaInfo: {
      title: 'Love is in the socks - NFT collectibles',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Love is in the socks - NFT collectible',
      }],
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Home'
    }),

    // -------------------------------------------- computed properties
    computed: {
      ...mapGetters({
        getAllProjects: 'projects/getAllProjects',
      }),

      // format data to an array
      projects: function () {
        return Object.entries(this.getAllProjects)
      },

      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';
    // Component name
    $COMPONENT: 'Home';
    .Home {
        // --------------------------------------------------------------------- CONFIG
        z-index: 1;
        // --------------------------------------------------------------------- BLOCK
        .Menu{
          position: absolute;
        }
        // --------------------------------------------------------------------- ELEMENTS
        &-text{
          text-align: center;
          width: 100vw;
          left:0;
          position: fixed;
          bottom: 10vh;
          /* @include mobile {
            width: 100%;
            position: relative;
            bottom: inherit;
          } */
        }
        &-pict{
          background-image: url('../assets/svg/LIITS-logo.svg');
          background-size: contain;
          background-position: center left;
          height: 100vh;
          width: 50vw;
          position: fixed;
          top: 50%;
          transform: translateY(-50%);
          left: 25vw;
          z-index: -1;
          overflow: hidden;

          /* @include mobile{
            display: none;
          }
          &-mobile {
            display: none;
            @include mobile{
              background-image: url('../assets/svg/LIITS-logo.svg');
              background-size: contain;
              background-position: center left;
              display: block;
              background-position: center center;
              height: 32vh;
              width: 90vw;
              top: 25vh;
              left: 5vw;
              z-index: -1;
              overflow: hidden;
              margin: 20px 0 0;
            }
          } */
        }
        &-background{
          background-color: $home;
          width: 100vw;
          min-height: 100vh;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
        }

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS
        p{
          margin: 30px 0;
        }

        h1 {
          @include mobile {
            font-size: 31px;
            margin: 20px 0;
          }
        }
        h3 {
          @include mobile {
            font-size: 36px;
          }
        }
        .button{
          margin: auto;
        }

        // --------------------------------------------------------------------- PATCHES

    }

</style>

