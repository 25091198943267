<template>
  <div :class="classBlock">
    <div :class="classBlock + '-background background'"></div>
    <header-cc logoColor="white"></header-cc>
    <div :class="classBlock+'-center'">
      <v-container fill-height :class="[classBlock + '-content', 'page-content']">
        <v-row align="center" justify="center">
          <v-col sm="12" md="6">
            <div>
              <div :class="classBlock+'-pict'"></div>
            </div>
          </v-col>
          <v-col :class="classBlock+'-col'" align="center" justify="center" sm="12" md="6">
            <div :class="classBlock+'-center-content'">
              <h1>
                Contact
              </h1>
              <p>
                soon
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import HeaderCc from '../components/HeaderCc.vue'
  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'Contact',
    // components imported
    components: {HeaderCc},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // metas
    metaInfo: {
      metaInfo: {
      title: 'Love is in the socks - NFT collectibles',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Love is in the socks - NFT collectible',
      }],
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Contact'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';
    // Component name
    $COMPONENT: 'Contact';
    .Contact {
        // --------------------------------------------------------------------- CONFIG
        *{
          color: $white;
          border-color: $white
        }
        // --------------------------------------------------------------------- BLOCK

        // --------------------------------------------------------------------- ELEMENTS
        &-background{
          background-color:$contact;
          width: 100vw;
          min-height: 100vh;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
        }
        &-pict{
          background-image: url('../assets/img/contact.png');
          background-size: contain;
          background-position: center left;
          display: block;
          background-position: center center;
          height: 50vh;
          width: 50vw;
          margin: auto;
        }
        &-center{
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          width: 100%;
          &-content{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            @include mobile {
              position: relative;
              transform: inherit;
              top: inherit;
              left: inherit;
            }
          }
        }
        p{
          margin:30px 0
        }
        &-content{
          text-align: center;
        }
        &-col{
          position: relative;
        }
        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN
        

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

