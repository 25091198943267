// imports
import Vue from 'vue'
import Vuex from 'vuex'

// modules import
import projects from './modules/projects'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    storesIsLoaded: false
  },
  getters: {
    getStoresIsLoaded: function (state) {
      return state.storesIsLoaded
    }
  },
  mutations: {
    setLoadedState: function (state, bool) {
      state.storesIsLoaded = bool
    }
  },
  actions: {
    initStores: function({ commit, dispatch }) {
      dispatch('projects/initStore').then(() => {
        commit('setLoadedState', true)
      })
    }
  },
  modules: {
    projects
  }
})
