import anime from 'animejs'
// doc : https://animejs.com/documentation/

export const ETransitionType = {
  LEFT : 'left',
  RIGHT : 'right'
}

// --------------------------------------------------- Enter transitions
export let enterTransition = function (type, done) {
  switch (type) {
    case ETransitionType.LEFT: 
      return enterTimelineLeft(done);
    case ETransitionType.RIGHT: 
      return enterTimelineRight(done);
  }
}

let enterTimelineLeft = function (done) {
  return anime.timeline({
    autoplay: true,
    duration: 375,
    easing: 'easeOutElastic',
  }).add({
    targets: '.backgrpound',
    opacity: 0,
    duration: 0,
  }).add({
    targets: '.backgrpound',
    opacity: 1,
    duration: 250,
  }).add({
    targets: '.page-content',
    opacity: 1,
    duration: 500,
    complete: done
  }, '-=125');
}

let enterTimelineRight = function (done) {
  console.log('openTimeline')
  return anime.timeline({
    autoplay: true,
    duration: 375,
    easing: 'easeOutElastic',
  }).add({
    targets: '.backgrpound',
    opacity: 1,
    duration: 250,
  }).add({
    targets: '.title',
    translateX: 100,
    opacity: 1,
    duration: 250,
  }).add({
    targets: '.desc',
    translateX: 50,
    opacity: 1,
    duration: 250,
    complete: done
  }, '-=125');
}

// --------------------------------------------------- Leave transitions
export let leaveTransition = function (type, done) {
  switch (type) {
    case ETransitionType.RIGHT: 
      return leaveTimelineRight(done);
    case ETransitionType.LEFT: 
      return leaveTimelineLeft(done);
  }
}

let leaveTimelineLeft = function( done) {
  return anime.timeline({
    autoplay: true,
    duration: 500,
    easing: 'linear',
  }).add({
    targets: '.background',
    opacity: 0,
    duration: 250,
  }).add({
    targets: '.page-content',
    opacity: 0,
    duration: 500,
    complete: done
  }, '-=250');
}

let leaveTimelineRight = function( done) {
  return anime.timeline({
    autoplay: true,
    duration: 375,
    easing: 'linear',
  }).add({
    targets: '.background',
    opacity: 0,
    duration: 250,
  }).add({
    targets: '.title',
    translateX: 1000,
    opacity: 0,
    duration: 250,
  }).add({
    targets: '.desc',
    translateX: 1000,
    opacity: 0,
    duration: 250,
    complete: done
  }, '-=125');
}
